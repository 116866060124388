<template>
  <div class="header" :class="needSticky ? '-sticky' : ''">
    <div class="left">
      <div class="guide-container" @mouseenter.prevent="isLeftDropOpen = true" @mouseleave.prevent="isLeftDropOpen = false">
        <button class="guide-icon" @click.stop="isLeftDropOpen  = !isLeftDropOpen">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div ref="leftDropRef" id="dropdownContainer" class="guide-dd" :class="isLeftDropOpen ? 'isOpen':''">
          <div class="guide-dd-menutab">
            <div class="left">
              <div class="hl-menus">
                <div class="menu" @click="toRouter('/download')">
                  <img src="@/assets/pc/header/svg-down.svg" alt="">
                  <span>{{t('下载')}}</span>
                </div>
              </div>
              <div class="multipage-menus">
                <ul>
                  <li>
                    {{t('关于我们')}}
                  </li>
                </ul>
              </div>
              <div class="standard-button-container login-button-container">
                <button v-if="!isLogged" class="standard-submit-button" @click="toLoginPop()">{{t('登入')}}</button>
                <button v-else class="standard-out-button" @click="toQuitlogin()">{{t('退出')}}</button>
              </div>
            </div>
            <div class="right">
              <div class="common-menus">
                <div class="menu" @click="toRouter('/')">
                  <img src="@/assets/pc/header/svg-home-page.svg" alt="">
                  <span>{{t('主页面')}}</span>
                </div>
                <div v-for="(item,index) in navObj.list" class="menu" @click="itemClick(item,index)">
                  <img :src="comApi.getNormalImg(item.Icon)" alt="">
                  <span>{{tools.getCurLangValue(item.Title)}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header-logo">
        <img class="brand-logo pointer" :src="comApi.getLogoImg()" alt="" @click="toRouter('/')">
      </div>
      <div class="navMenu-container">
        <ul class="menu-warpper">
          <li v-for="(item, index) in navObj.list" :class="navObj.active == index ? 'active' : ''" class="navbar-item" @click="itemClick(item, index)" @mouseenter="navObj.mouseIndex = index" @mouseleave="navObj.mouseIndex = -1">
            <a class="headerLink">
              <div class="menu-item-title ">
                <p class="m-0">{{tools.getCurLangValue(item.Title)}}</p>
              </div>
            </a>
            <div class="header-pop" v-show="item.SubFactory && Object.keys(item.SubFactory).length > 0  && navObj.mouseIndex == index">
              <ol class="com-container">
                <li v-for="(item1, index) in item.SubFactory" :key="item1" @click.stop="popItemClick(item,item1, index)">
                  <div class="frame">
                    <img :src="comApi.getFactoryImg(item1.factoryInfo)">
                  </div>
                </li>
              </ol>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="right">
      <div class="login-bar">
        <div class="loginBar">
          <div class="top">
            <div class="time-cantainer">
              <time>{{timeData}}</time>
              &nbsp;
              <span>({{tools.convertToGMT()}})</span>
            </div>
          </div>
          <div class="bottom">
            <template v-if="!isLogged">
              <button class="btn-login" @click="toLoginPop()">{{t('登入')}}</button>
              <button class="btn-join" @click="toRegister()">
                <span>{{t('现在加入')}}</span>
              </button>
            </template>
            <template v-else>
              <div class="nav-myacc">
                <button class="account-icon" @click="toRouter('/member/cashier/deposit/depositInfo')">
                  <img src="@/assets/pc/header/svg-user.svg" alt="">
                </button>
              </div>
              <div class="icon-msg">
                <div class="unread-message-icon" @click="toRouter('/member/mailBox/mailBoxAccount')">
                  <img src="@/assets/pc/header/svg-email.svg" alt="">
                  <span v-if="mailInfo.mailUnreadNumAll>0" class="unread-message">{{mailInfo.mailUnreadNumAll >99 ? '+99':mailInfo.mailUnreadNumAll}}</span>
                </div>
              </div>
              <div class="acc-wallet" @click="toWalletPop()">
                <div class="acc-walle-container">
                  <div class="wallet-balance">
                    <p>
                      <span>{{tools.formatNum2k(wallet.VndBalance)}}</span>
                    </p>
                  </div>
                  <div class="icon-add">
                    <img src="@/assets/pc/header/svg-add-money.svg" alt="">
                  </div>
                </div>
              </div>
              <div class="funds-btn-container">
                <button class="">{{t('存取款')}}</button>
                <div class="user-pop">
                  <div class="item" @click="toRouter('/member/cashier/deposit/depositInfo')">
                    <img src="@/assets/pc/member/icon_cz.png" alt="">
                    <span>{{t('存款')}}</span>
                  </div>
                  <div class="item" @click="toRouter('/member/cashier/withdrawal/withdrawalInfo')">
                    <img src="@/assets/pc/member/icon_cashier.png" alt="">
                    <span>{{t('提款')}}</span>
                  </div>
                  <div class="item" @click="toQuitlogin()">
                    <img src="@/assets/pc/member/ic-menu-logout.png" alt="">
                    <span>{{t('退出')}}</span>
                  </div>
                </div>
              </div>
            </template>
            <div class="language-box">
              <button class="change-language-btn" @click.stop="showLang = !showLang">
                <img class="lang_img" :src="getCurLangIcon(locale)" alt="">
                <img class="lang_flag" src="@/assets/pc/common/lang_xiala.svg" :class="showLang ? 'rotate':''" alt="">
              </button>
              <div v-show="showLang" ref="langRef" class="ChangeLanguageDropdown_root_X4LnD ChangeLanguageDropdown_rootDirectionBottom_YSGc1">
                <img class="icon icon-tooltip-arrow-2 sm ChangeLanguageDropdown_decor_YdiR1 ChangeLanguageDropdown_decor_YdiR1" src="@/assets/pc/common/lang-down.svg" alt="">
                <div class="ChangeLanguageDropdown_wrapper_cA7ZV">
                  <div class="LanguagesBlock_root_cix6n">
                    <div class="LanguagesBlock_subtitle_Ag3lt">{{t('区域 及 语言')}}</div>
                    <div class="LanguagesList_root_z8xst">
                      <div v-for="(item,index) in tools.getLanguageList()" class="LanguagesList_item_ITMix" :class="locale == item.shortName ? 'LanguagesList_itemActive_TC9Ls':'' " @click="langItemClick(item)">
                        <button class=" LanguagesList_itemButton_l1VGg">
                          <img class="lang-icon" :src="comApi.getNormalImg(item.icon)" alt="">
                          <span class="LanguagesList_itemInner_YiFFQ">
                            <span class="LanguagesList_itemTitle_A98V0">{{item.shortName}}</span>
                            <span class="LanguagesList_itemCaption_NuAGO">{{item.name}}</span>
                          </span>
                          <img v-if="locale == item.shortName" class="lang-select" src="@/assets/pc/common/svg-lang-select.svg" alt="">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo, mailInfo } = useGetters("user", ["userInfo", "mailInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
const { loginOut } = useMutations('user', ['loginOut'])
const { disConnect } = useActions('tcp', ['disConnect'])

const needSticky = ref(false);

const leftDropRef = ref(null);
const isLeftDropOpen = ref(false);

const showNav = ref(false);
const canShowPop = ref(false);


const langRef = ref(null);
const showLang = ref(false);

let timeFuc = null;
let timeData = ref('');
onMounted(() => {
  window.addEventListener("scroll", () => {
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop > 70) {
      needSticky.value = true
    } else {
      needSticky.value = false
    }
  })
  document.addEventListener('click', (e) => {
    if (leftDropRef.value) {
      if (!leftDropRef.value.contains(e.target)) {
        if (isLeftDropOpen.value) {
          isLeftDropOpen.value = false;
        }
      }
    }
    if (langRef.value) {
      if (!langRef.value.contains(e.target)) {
        if (showLang.value) {
          showLang.value = false;
        }
      }
    }
  })
  timeData.value = tools.formatDate(new Date())
  timeFuc = setInterval(() => {
    timeData.value = tools.formatDate(new Date())
  }, 1000)
})

onBeforeUnmount(() => {
  timeFuc && clearTimeout(timeFuc)
})
const navObj = reactive({
  active: -1,
  mouseIndex: -1,
  list: getNavList(),
})


function getNavList() {
  let routerList = comApi.getRouterList();
  log.info('routerHeaderList---', routerList)
  return routerList;
}

//处理选中路由 
function setSelectIndex() {
  let hasInRouter = false;
  for (let key in navObj.list) {
    let info = navObj.list[key];
    if (info.router && info.router == curRouter.path) {
      hasInRouter = true;
      navObj.active = Number(key);
    }
  }
  if (!hasInRouter) {
    navObj.active = -1;
    return;
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
})

function getCurLangIcon(lang) {
  let allList = tools.getLanguageList();
  for (let key in allList) {
    if (allList[key].shortName == lang) {
      return comApi.getNormalImg(allList[key].icon)
    }
  }
}

function getLangImg(flag) {
  return require(`@/assets/common/lang/${flag}.png`)
}

const changeHoverState = (bool) => {
  //重置刷新canShowPop的状态，很重要
  canShowPop.value = bool;
};

function quitLogin() {
  loginOut();
  disConnect();
  ElMessage.success(t('Exit successful'));
  router.push('/');
}


function langItemClick(item) {
  if (item.shortName == locale.value) return;
  locale.value = item.shortName;
  localStorage.setItem('localMyLanguage', item.shortName);
  showLang.value = false;
  tools.reload();
}

function itemClick(item, index) {
  navObj.active = index;
  if (item.router) {
    router.push({
      path: item.router,
    })
    isLeftDropOpen.value = false;
  }
}

function popItemClick(item, item1, factoryName) {
  if (item.router) {
    router.push({
      path: item.router,
      query: {
        fc: factoryName
      }
    })
  }
}

function toQuitlogin() {
  bus.emit(bus.event.commonTipPop, {
    bool: true,
    data: {
      title: t('Want to log out?'),
      confirmTitle: t('Log out'),
      cannel: true,
      func: quitLogin
    }
  })
}

function toSlot() {
  window.sessionStorage.setItem("slotRouterParams", '');
  router.push({
    path: '/slot'
  })
}

function toRouter(path) {
  router.push({
    path: path
  })
}

function toRegister() {
  bus.emit(bus.event.resgisterPop, true);
}

function toLoginPop() {
  bus.emit(bus.event.loginState, true);
}

function toWalletPop() {
  bus.emit(bus.event.walletPop, true);
}
</script>

<style lang="scss" scoped>
.header {
  background: #2e1f40;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 70px;
  z-index: 1000;
  position: relative;
  backdrop-filter: blur(6px);
  .left {
    display: flex;
    align-items: center;
    height: 100%;
    .guide-container {
      .guide-icon {
        cursor: pointer;
        border-radius: 25px;
        color: #fff;
        border: none;
        background: none;
        margin-right: 15px;
        margin-top: 6px;
        width: 25px;
        height: 24px;
        position: relative;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;
        span {
          background: #8697a2;
          width: 70%;
          display: block;
          position: absolute;
          height: 3px;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          transition: 0.25s ease-in-out;
        }
        span:first-child {
          top: 0;
          animation: x-hamburger-wave 0.4s ease-out infinite alternate;
        }
        span:nth-child(2) {
          top: 9px;
          animation: x-hamburger-wave 0.4s ease-out 0.3s infinite alternate;
        }
        span:nth-child(3) {
          top: 18px;
          animation: x-hamburger-wave 0.4s ease-out 0.6s infinite alternate;
        }
        @keyframes x-hamburger-wave {
          0% {
            width: 70%;
          }
          100% {
            width: 100%;
          }
        }
      }
      .guide-dd {
        display: block;
        position: absolute;
        background: #2e1f40;
        top: 70px;
        box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        z-index: 10;
        max-height: 0;
        transition: max-height 0.5s ease-in-out;
        overflow: hidden;
        .guide-dd-menutab {
          display: flex;
          padding: 20px;
          width: 550px;
          justify-content: space-between;
          .left {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            width: 50%;
            border-right: 1px solid #f7f7f7;
            .hl-menus {
              .menu {
                align-items: center;
                display: flex;
                margin: 0 0 28px;
                font-size: 18px;
                color: #fff;
                cursor: pointer;
                img {
                  width: 38px;
                  margin: 0 14px 6px 0;
                }
                span {
                }
              }
              .menu:hover {
                color: #ffbf00;
              }
            }
            .multipage-menus {
              margin: 30px 60px 0 0;
              ul {
                font-size: 13px;
                clear: both;
                color: #8697a2;
                display: flex;
                flex-direction: column;
                li {
                  list-style: disc;
                  float: left;
                  margin: 0 15px 10px 0;
                  list-style-position: inside;
                  cursor: pointer;
                }
                li::marker {
                  font-size: 12px;
                }
              }
            }
            .standard-button-container {
              margin-top: 47px;
              justify-content: start;
              width: 100%;
              display: flex;
              align-items: center;
              .standard-submit-button {
                width: 209px;
                height: 40px;
                border-radius: 10px;
                background: #ffbf00;
                color: #fff;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 170px;
                border: none;
              }
              .standard-submit-button:hover {
                background: #ff7200;
              }
              .standard-out-button {
                width: 209px;
                height: 40px;
                border-radius: 10px;
                background: #f3f5f6;
                color: #8697a2;
                font-size: 16px;
                font-weight: 600;
                border: none;
                cursor: pointer;
              }
            }
          }
          .right {
            width: 50%;
            z-index: 10;
            height: 100%;
            .common-menus {
              padding-left: 77px;
              .menu {
                margin: 0 0 16px;
                font-size: 14px;
                color: #fff;
                display: flex;
                align-items: center;
                cursor: pointer;
                text-transform: capitalize;
                img {
                  width: 32px;
                  height: 32px;
                  margin: 0 12px 6px 0;
                }
                span {
                }
              }
              .menu:hover {
                color: #ffbf00;
              }
            }
          }
        }
      }
      .isOpen {
        max-height: 750px;
      }
    }
    .header-logo {
      margin: 0 24px 0 20px;
      cursor: pointer;
      img {
        width: 60px;
        height: 60px;
        // max-height: 60%;
      }
    }
    .navMenu-container {
      height: 100%;
      text-transform: unset;
      font-size: 15px;
      font-weight: 400;
      .menu-warpper {
        display: flex;
        justify-content: space-between;
        height: 100%;
        .navbar-item {
          padding: 0;
          height: 100%;
          cursor: pointer;
          text-align: unset;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          margin: 0 24px 0 0;
          a {
            color: #fff;
            text-decoration: none;
            background-color: transparent;
            outline: none;
            cursor: pointer;
            -webkit-transition: color 0.3s;
            transition: color 0.3s;
            .menu-item-title {
              display: flex;
              align-items: center;
              margin: 5px 0 0;
              p {
                white-space: nowrap;
              }
            }
          }
          .header-pop {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            width: 100%;
            z-index: 3;
            background: rgba(0, 15, 76, 0.8);
            border-bottom: unset solid 0.06rem;
            backdrop-filter: blur(0.05rem);
            white-space: nowrap;
            // visibility: hidden;

            ol {
              position: relative;
              margin: 0 auto;
              display: flex;
              flex-wrap: wrap;
              gap: 10px;
              padding: 10px;
              li {
                width: calc(100% / 10);
                padding: 10px;
                .frame {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  font-size: 14px;
                  cursor: pointer;
                  border-radius: 10px;
                  overflow: hidden;
                  transition: all 0.2s;
                  img {
                    width: 100%;
                    object-fit: cover;
                  }

                  .gray {
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                    cursor: not-allowed;
                  }
                }

                .frame:hover {
                  background: rgba(255, 255, 255, 0.1);
                  transform: scale(1.1);
                }
              }
            }
          }
        }
        .navbar-item:hover {
          a {
            color: #ffbf00;
          }
        }
        .active {
          background: 0 0;
          a {
            color: #ffbf00;
          }
        }
      }
    }
  }
  .showPop {
    max-height: 700px !important;
  }

  // .game-pop {
  //   background-color: #292e3b;
  //   top: 140px;
  //   background-image: none;
  //   width: 100%;
  //   max-height: 0;
  //   overflow: hidden;
  //   position: absolute;
  //   z-index: 5000;
  //   display: flex;
  //   justify-content: center;
  //   box-sizing: border-box;
  //   box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
  //   transition: all 0.3s;

  //   .luckyWin {
  //     width: 1400px;
  //     padding-bottom: 10px;
  //     position: relative;

  //     :deep(.swiper) {
  //     }

  //     .navPlatItem {
  //       width: 180px;
  //       height: 210px;
  //       margin: 10px;
  //       cursor: pointer;
  //       position: relative;

  //       .cover {
  //         background: rgba(30, 34, 44, 0.2);
  //         padding: 80px 20px 0 !important;
  //         border-radius: 6px;
  //         display: none;
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //         bottom: 0;
  //         right: 0;
  //         z-index: 1000;

  //         .btn-play {
  //           min-width: 132px;
  //           height: 40px;
  //           background: #4a69ff;
  //           box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, 0.4);
  //           border-radius: 20px;
  //           display: flex;
  //           align-items: center;
  //           color: #fff;
  //           padding: 0 16px;
  //           cursor: pointer;
  //           box-sizing: border-box;
  //           font-size: 13px;

  //           .icon-play {
  //             width: 24px;
  //             height: 24px;
  //             background: #fff;
  //             box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  //             border-radius: 12px;
  //           }

  //           .icon-play::after {
  //             display: inline-block;
  //             content: "";
  //             border: 6px solid transparent;
  //             border-left-color: #677684;
  //             margin-left: 10px;
  //             margin-top: 6px;
  //           }

  //           .btn-txt {
  //             flex: 1;
  //             text-align: center;
  //           }
  //         }
  //       }

  //       .name {
  //         position: absolute;
  //         border: 1px solid #677684;
  //         border-radius: 6px;
  //         left: 50%;
  //         bottom: 16px !important;
  //         -webkit-transform: translateX(-50%);
  //         transform: translateX(-50%);
  //         width: 92%;

  //         p {
  //           height: 28px;
  //           line-height: 28px;
  //           color: #fff;
  //           font-size: 14px;
  //           text-align: center;
  //         }
  //       }

  //       .imgContainer {
  //         width: 100%;
  //         height: 100%;
  //         position: relative;

  //         img {
  //           position: absolute;
  //           width: 100%;
  //         }
  //       }
  //     }

  //     .navPlatItem:hover .cover {
  //       display: block;
  //     }
  //   }

  //   .liveCasino {
  //     width: 1400px;
  //     padding-bottom: 10px;
  //     position: relative;

  //     .navPlatItem {
  //       width: 194px;
  //       height: 245px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       padding-top: 10px;
  //       transition: all 0.5s;
  //       margin: 0 12px;
  //       position: relative;

  //       .content {
  //         width: 100%;
  //         height: 100%;

  //         .cover {
  //           display: none;
  //           position: absolute;
  //           top: 0;
  //           left: 0;
  //           bottom: 0;
  //           right: 0;
  //           z-index: 100000;
  //           background: rgba(30, 34, 44, 0.2);
  //           padding: 110px 20px 0;
  //           border-radius: 6px;

  //           .btn-play {
  //             min-width: 132px;
  //             height: 40px;
  //             background: #4a69ff;
  //             box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, 0.4);
  //             border-radius: 20px;
  //             display: flex;
  //             align-items: center;
  //             color: #fff;
  //             padding: 0 16px;
  //             cursor: pointer;
  //             box-sizing: border-box;
  //             font-size: 13px;

  //             .icon-play {
  //               width: 24px;
  //               height: 24px;
  //               background: #fff;
  //               box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  //               border-radius: 12px;
  //             }

  //             .icon-play::after {
  //               display: inline-block;
  //               content: "";
  //               border: 6px solid transparent;
  //               border-left-color: #677684;
  //               margin-left: 10px;
  //               margin-top: 6px;
  //             }

  //             .btn-txt {
  //               flex: 1;
  //               text-align: center;
  //             }
  //           }
  //         }

  //         .liveContainer {
  //           display: flex;
  //           flex-direction: column;
  //           align-items: center;

  //           .top {
  //             width: 144px;
  //             height: 144px;
  //             overflow: hidden;
  //             border-radius: 72px;
  //             background-image: url("@/assets/pc/header/img_casino_nav_bg_dark.png");
  //             background-size: 100% 100%;
  //             position: relative;
  //             box-shadow: none;

  //             .foreground {
  //               width: 144px;
  //               top: 40px;
  //               position: absolute;
  //             }

  //             .croupier {
  //               top: 10px;
  //               left: 10px;
  //               width: 130px;
  //               position: absolute;
  //             }
  //           }

  //           .bot {
  //             width: 174px;
  //             height: 70px;
  //             margin-top: 10px;
  //             text-align: center;
  //             border-radius: 35px;
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: center;
  //             box-shadow: none;
  //             border: 1px solid #363d4e;

  //             .logo {
  //               flex-shrink: 0;
  //               height: 32px;
  //               width: 100%;

  //               .aelive {
  //                 height: 100%;
  //               }
  //             }

  //             p {
  //               margin-top: 2px;
  //               font-size: 14px;
  //               font-weight: 500;
  //               color: #fff;
  //             }
  //           }
  //         }
  //       }
  //     }

  //     .navPlatItem:hover .content .cover {
  //       display: block;
  //     }
  //   }

  //   .sports-chess {
  //     width: 1410px;
  //     height: auto;
  //     display: flex;
  //     justify-content: center;
  //     flex-wrap: wrap;
  //     position: relative;
  //     z-index: 99999;

  //     li {
  //       .navPlatItem {
  //         margin: 10px;
  //         width: 214px;
  //         height: 320px;
  //         cursor: pointer;
  //         position: relative;

  //         .cover {
  //           background: rgba(30, 34, 44, 0.2);
  //           padding: 140px 41px 0;
  //           border-radius: 6px;
  //           display: none;
  //           position: absolute;
  //           top: 0;
  //           left: 0;
  //           bottom: 0;
  //           right: 0;
  //           z-index: 100000;

  //           .btn-play {
  //             min-width: 132px;
  //             height: 40px;
  //             background: #4a69ff;
  //             box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, 0.4);
  //             border-radius: 20px;
  //             display: flex;
  //             align-items: center;
  //             color: #fff;
  //             padding: 0 16px;
  //             cursor: pointer;
  //             box-sizing: border-box;
  //             font-size: 13px;

  //             .icon-play {
  //               width: 24px;
  //               height: 24px;
  //               background: #fff;
  //               box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  //               border-radius: 12px;
  //             }

  //             .icon-play::after {
  //               display: inline-block;
  //               content: "";
  //               border: 6px solid transparent;
  //               border-left-color: #677684;
  //               margin-left: 10px;
  //               margin-top: 6px;
  //             }

  //             .btn-txt {
  //               flex: 1;
  //               text-align: center;
  //             }
  //           }
  //         }

  //         .name {
  //           border: 1px solid #677684;
  //           border-radius: 6px;
  //           left: 50%;
  //           bottom: 4px;
  //           -webkit-transform: translateX(-50%);
  //           transform: translateX(-50%);
  //           width: 92%;
  //           position: absolute;

  //           p {
  //             height: 28px;
  //             line-height: 28px;
  //             color: #fff;
  //             font-size: 14px;
  //             text-align: center;
  //           }
  //         }

  //         .imgContainer {
  //           overflow: hidden;
  //           width: 100%;
  //           height: 100%;
  //           position: relative;

  //           .main {
  //             z-index: 1;
  //             position: absolute;
  //             width: 100%;
  //           }

  //           .bot {
  //             bottom: 45px;
  //             position: absolute;
  //             width: 100%;
  //           }

  //           .logo {
  //             // left: 30px;
  //             top: 26px;
  //             height: 30px;
  //             width: auto;
  //             position: absolute;
  //           }
  //         }
  //       }

  //       .navPlatItem:hover .cover {
  //         display: block;
  //       }
  //     }

  //     .disabled {
  //       cursor: not-allowed !important;
  //       -webkit-filter: grayscale(100%);
  //       filter: grayscale(100%);
  //     }
  //   }
  // }
  .right {
    z-index: 10;
    height: 100%;
    .login-bar {
      height: 100%;
      .loginBar {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        .top {
          position: absolute;
          top: 1px;
          right: 0;
          .time-cantainer {
            color: #fff;
            font-size: 11px;
            margin: 5px 0;
            text-align: right;
            display: flex;
            gap: 5px;
            align-items: center;
            time {
              white-space: nowrap;
            }
            span {
            }
          }
          .time-cantainer > time {
            display: inline-block;
            min-width: 130px;
            text-align: right;
            margin-right: -8px;
          }
        }
        .bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 8px 0 0;
          white-space: nowrap;
          button {
            border: none;
          }
          .btn-login {
            color: #fff;
            font-size: 16px;
            background: transparent;
            cursor: pointer;
          }
          .btn-join {
            background: #ffbf00;
            color: #fff;
            border-radius: 5px;
            font-size: 16px;
            height: 35px;
            padding: 5px 15px;
            margin: 0 15px;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            animation: 1.5s ease 0s infinite normal none move;
          }
          @keyframes move {
            0% {
              opacity: 0.9;
            }
            10% {
              transform: translate3d(-1px, 0, 0);
            }
            70% {
              transform: scale(1);
              box-shadow: rgba(255, 138, 0, 0.3764705882) 0 0 0 15px;
            }
            80% {
              transform: translate3d(-1px, 0, 0);
            }
            100% {
              transform: scale(1);
              box-shadow: transparent 0 0 0 22px;
              opacity: 1;
            }
          }
          .nav-myacc {
            position: relative;
            margin: 0 12px 0 0;
            height: 100%;
            display: flex;
            align-items: center;
            .account-icon {
              background: #ffbf00;
              padding: 5px;
              border-radius: 50%;
              width: 32px;
              height: 32px;
              border: none;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
          .icon-msg {
            position: relative;
            margin: 0 12px 0 0;
            height: 100%;
            display: flex;
            align-items: center;
            .unread-message-icon {
              position: relative;
              margin-top: 5px;
              img {
                fill: #0072e2;
                width: 30px;
                height: 30px;
              }
              .unread-message {
                background: red;
                border-radius: 50%;
                color: #fff;
                padding: 2px 5px;
                font-size: 10px;
                position: absolute;
                right: -5px;
                top: -3px;
              }
            }
          }
          .acc-wallet {
            position: relative;
            margin: 10px 12px 10px 0;
            display: flex;
            align-items: center;
            height: 100%;
            border-left: 1px solid #8697a2;
            cursor: pointer;
            .acc-walle-container {
              display: flex;
              align-items: center;
              .wallet-balance {
                margin: 0 8px 0 12px;
                p {
                  margin-bottom: 0;
                  font-size: 12px;
                  white-space: nowrap;
                  color: #8697a2;
                  span {
                    font-size: 14px;
                    color: #0f161f;
                  }
                }
              }
              .icon-add {
                img {
                  width: 18px;
                  height: 18px;
                  fill: #fff;
                  background: #d5dbe1;
                  padding: 4px;
                  border-radius: 20px;
                }
              }
            }
          }
          .funds-btn-container {
            position: relative;
            margin: 0 15px 0 0;
            height: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;
            button {
              padding: 10px 15px;
              background: #0072e2;
              color: #fff;
              cursor: pointer;
              border-radius: 5px;
              font-size: 16px;
              height: 35px;
              display: flex;
              align-items: center;
              border: none;
            }
            .user-pop {
              position: absolute;
              left: 50%;
              transform: translate(-50%);
              top: 35px;
              width: 200px;
              padding: 18px;
              background: #fff;
              -webkit-box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.2);
              box-shadow: 0 5px 8px -2px rgba(0, 0, 0, 0.2);
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              gap: 10px;
              display: none;
              .item {
                text-align: left;
                padding: 5px 0 5px 20px;
                display: flex;
                align-items: center;
                gap: 10px;
                border-radius: 20px;
                background: #f7f7f7;
                color: #0f161f;
                cursor: pointer;
                img {
                  width: 25px;
                  height: 25px;
                }
                span {
                  font-size: 16px;
                }
              }
              .item:hover {
                background: #ffbf00;
                color: #fff;
              }
              .active {
                background: #ffbf00;
                color: #fff;
              }
            }
          }
          .funds-btn-container:hover {
            .user-pop {
              display: flex;
            }
          }
          .language-box {
            position: relative;
            .change-language-btn {
              background: #f3f5f6;
              display: flex;
              align-items: center;
              border-radius: 20px;
              padding: 5px 8px;
              height: 32px;
              .lang_img {
                width: 25px;
                height: 25px;
                border: 0;
                border-radius: 50%;
                margin-right: 10px;
              }
              .lang_flag {
                width: 12px;
                fill: #afb3b6;
                transition: all 0.3s;
              }
              .rotate {
                transform: scaleY(-1);
              }
            }
            .ChangeLanguageDropdown_rootDirectionBottom_YSGc1 {
              top: 100%;
              right: 0;
              padding-top: 9px;
            }
            .ChangeLanguageDropdown_root_X4LnD {
              position: absolute;
              width: 315px;
              max-height: 435px;
              z-index: 1000;
              display: flex;
              flex-direction: column;
              box-sizing: content-box;
            }
            .ChangeLanguageDropdown_decor_YdiR1 {
              top: 5px;
              right: 25px;
              position: absolute;
              width: 12px;
              height: 4px;
            }
            .ChangeLanguageDropdown_wrapper_cA7ZV {
              display: flex;
              flex-direction: column;
              max-height: 435px;
              border-radius: 15px;
              background-color: #fff;
              box-shadow: 0 0 4px #fffa, 0 4px 4px #fffb;
              overflow: hidden;
              flex-shrink: 0;
              .LanguagesBlock_root_cix6n {
                max-height: 100%;
                padding: 15px;
                overflow-y: auto;
                box-sizing: border-box;
                .LanguagesBlock_subtitle_Ag3lt {
                  margin-bottom: 10px;
                  font-size: 14px;
                  line-height: 17px;
                  font-weight: 600;
                }
                .LanguagesList_root_z8xst {
                  color: #000;
                  display: flex;
                  flex-direction: column;
                  border-radius: 10px;
                  overflow: hidden;
                  .LanguagesList_item_ITMix {
                    background-color: #20283c;
                    transition: background-color 0.2s ease-in-out;
                  }
                  .LanguagesList_item_ITMix:hover {
                    background-color: #2a3349;
                  }
                  .LanguagesList_item_ITMix:not(:last-child) {
                    margin-bottom: 1px;
                  }
                  .LanguagesList_itemActive_TC9Ls {
                    background-color: #2a3349;
                    pointer-events: none;
                  }
                  .LanguagesList_itemButton_l1VGg {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 9px 17px;
                    .lang-icon {
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      object-fit: cover;
                    }
                    .LanguagesList_itemInner_YiFFQ {
                      flex: 1;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                      margin: 0 12px;
                      .LanguagesList_itemTitle_A98V0 {
                        margin-bottom: 3px;
                        font-size: 13px;
                        line-height: 16px;
                        font-weight: 600;
                      }
                      .LanguagesList_itemCaption_NuAGO {
                        color: #77829b;
                        font-size: 11px;
                        line-height: 13px;
                        text-transform: capitalize;
                      }
                    }
                    .lang-select {
                      width: 15px;
                      height: 15px;
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}
</style>