<template>
  <div class="root">
    <div class="top top-provider-img">
      <img loading="lazy" src="@/assets/pc/banner/sports-bn.jpg" class="provider-banner" alt="">
      <!-- <div class="top-provider-content ">
        <p class="provider-type">{{t('体育博彩')}}</p>
        <div class="provider-desc">
          <p>{{t('享受世界级线上体育博彩')}}</p>
          <p>{{t('最高的体育博彩赔率')}}</p>
        </div>
      </div> -->
    </div>
    <div class="mid com-container">
      <ul class="provider-box">
        <li v-for="(item,index) in navObj.list" class="tab-header " :class="index == navObj.index ? 'active':''" @click="itemClick(item,index)">
          <p class="float-dot new-provider">
            {{t('最新')}}
          </p>
          <div class="tab-inner">
            <img :src="comApi.getNormalImg(item.factoryInfo.Icon)" alt="">
            <span>{{ item.factoryInfo.Name }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="navObj.showAni" class="bottom-category animated fadeInUp">
      <div class="bottom-content com-container">
        <div class="title">{{navObj.list[navObj.index].factoryInfo.Name}}-Sports</div>
        <div class="subtitle">{{t('亚洲信誉最高的线上体育博彩')}}</div>
        <div class="description">manila368 {{t('Sports是一款由市场领先的体育博彩开发商——beteasy88所开发并推出的体育博彩游戏。beteasy88旗下拥有超过百名专业职员和客服专员全年365天备勤，24小时待命为全球玩家提供最高的赔率和最多样的体育种类及赛事。')}}</div>
        <button @click="comApi.toFactory(navObj.list[navObj.index].factoryInfo)">{{t('进入游戏')}}</button>
      </div>
      <div class="bottom-content-img">
        <img loading="lazy" src="@/assets/pc/down/BTI.jpg" alt=""></div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import comApi from "@/utils/comApi";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from 'vue-router';
import i18n from "@/language/i18n";
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { useI18n } from "vue-i18n";
const { t, locale } = useI18n();
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);

const curType = 'LiveSports';
let curTypeObj = tools.deepClone((lobbyInfo.value.MainPageConf.find(info => info._id == curType)).SubFactory);
curTypeObj = tools.sortObject(curTypeObj, ['factoryInfo', 'Sort']);
let navList = getAllListByType(curType);
const navObj = reactive({
  index: 0,
  list: navList,
  showAni: true,
})

if (curRouter.query && curRouter.query.fc) {
  for (let key in navObj.list) {
    let info = navObj.list[key];
    if (info.factoryInfo.FactoryName == curRouter.query.fc) {
      navObj.index = Number(key);
      break;
    }
  }
}

function getAllListByType(type) {
  let arr = [];
  let curObj = lobbyInfo.value.MainPageConf.find(info => info._id == type).SubFactory;
  if (!curObj) return arr;
  for (let key in curObj) {
    arr.push(curObj[key])
  }
  arr.sort((a, b) => {
    return a.factoryInfo.Sort - b.factoryInfo.Sort;
  })
  log.info('getAllListByType', arr);
  return arr;
}

function itemClick(item, index) {
  if (index == navObj.index) return;
  navObj.index = index;
  navObj.showAni = false;
  nextTick(() => {
    navObj.showAni = true;
  })
}
function setSelectIndex() {
  let index = -1;
  if (curRouter.query && curRouter.query.fc) {
    for (let key in navObj.list) {
      let info = navObj.list[key];
      if (info.factoryInfo.FactoryName == curRouter.query.fc) {
        index = Number(key);
        break;
      }
    }
  }
  if (index != -1) {
    itemClick(null, index);
  }

}

watch(() => {
  curRouter.path
}, (newValue, oldValue) => {
  setSelectIndex();
}, { deep: true })

</script>

<style lang="scss" scoped>
.root {
  position: relative;
  .top {
    position: relative;
    margin: auto;
    width: 100%;
    height: 0;
    padding-bottom: 25%;
    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .top-provider-content {
      position: absolute;
      bottom: 15%;
      left: 15%;
      color: #000;
      width: 100%;
      word-break: normal;
      overflow-wrap: break-word;
      max-width: 1340px;
      .provider-type {
        font-weight: 600;
        font-size: 55px;
        margin: 0 0 5px;
      }
      .provider-desc {
        width: 27%;
        p:first-child {
          font-size: 30px;
          margin: 0 0 10px;
        }
        p:last-child {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
  .mid {
    background: #f3f5f6;
    border-radius: 10px;
    margin: 50px auto;
    padding: 22px 52px;
    .provider-box {
      display: grid;
      flex-wrap: wrap;
      grid-gap: 10px;
      grid-template-columns: repeat(7, 0.7fr);
      .tab-header {
        background: #fff;
        border: 1px solid transparent;
        border-radius: 50px;
        padding: 5px 0;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .float-dot {
          border-radius: 4px;
          font-size: 8px;
          padding: 3px 8px;
          position: absolute;
          right: -4px;
          text-align: center;
          top: -5px;
          line-height: normal;
          border-radius: 10px;
          font-family: helvetica neue, helvetica-regular;
        }
        .new-provider {
          background: #e0092c;
          color: #fff;
        }
        .tab-inner {
          align-items: center;
          display: flex;
          justify-content: center;
          opacity: 0.5;
          img {
            filter: grayscale(100%);
            margin: 0 5px 0 0;
            height: 20px;
            width: auto;
            object-fit: contain;
          }
          span {
            color: #8697a2;
            font-size: 14px;
            white-space: nowrap;
          }
        }
      }
      .tab-header:hover,
      .active {
        border: 1px solid #ffbf00;
        .tab-inner {
          opacity: 1;
          img {
            filter: grayscale(0%);
          }
          span {
            color: #0f161f;
          }
        }
      }
    }
  }
  .bottom-category {
    .bottom-content {
      align-items: center;
      color: #0f161f;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: auto;
      position: relative;
      z-index: 2;
      .title {
        font-weight: 600;
        font-size: 40px;
        margin: 0 0 20px;
      }
      .subtitle {
        font-size: 20px;
        margin: 0 0 20px;
      }
      .description {
        color: #8697a2;
        font-size: 14px;
        margin: 0 0 40px;
        text-align: center;
        width: 50%;
      }
      button {
        background: #ffbf00;
        border-radius: 10px;
        color: #fff;
        font-size: 16px;
        padding: 10px 0;
        width: 28%;
        border: none;
      }
      button:hover {
        background: #ff7200;
      }
    }
    .bottom-content-img {
      position: relative;
      z-index: 1;
      margin: -80px auto 0;
      img {
        display: block;
        margin: auto;
        max-width: 2000px;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}
</style>